import React from 'react';
import { useNavigate } from 'react-router-dom';
import rightArrow from '../../images/arrowRight.svg';


const AboutUs = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-center ">
      <h2 className="h-[30px] px-[18px] mb-[16px] font-bold border-none rounded-[50px] bg-momediumblue text-sm flex items-center justify-center">
        About Us
      </h2>
      <h3 className="text-xxl md:text-huge lg:text-xb font-[900] mb-[6px] lg:mb-[16px] ">The Modenbo Story</h3>
      <p className="px-[29px] md:px-[74px] lg:px-[129px] xl:px-[359px] text-center text-sm font-normal mb-[16px]">
        Modenbo Technologies was founded by two visionary entrepreneurs, Yaw and Crownie, driven by a shared passion to connect local insights with global innovation. Their mission is simple yet powerful: to empower businesses and individuals with advanced software solutions that make a real difference.
      </p>
      <div className='flex flex-row items-center gap-[130px] items-center justify-center'>
        <button onClick={() => { navigate("/about") }} className='px-[15px] h-[30px] lg:h-[40px] flex text-white text-center items-center justify-center text-base font-bold lg:text-sm lg:text-base xl:text-lg bg-modeepblue rounded-[50px] flex-shrink-0   '>
          Learn More
          <img src={rightArrow} alt="Arrow Icon" className="pl-[3px]" />
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
