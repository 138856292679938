import React from 'react';
import HomeNavigation from '../navigation/homeNavigation';
import Footer from '../footer/Footer';
import Carousel from '../home/homecarousel'
import AboutUs from '../home/homeabout'
import Services from './homeservices';
import Pricing from './homepricing';
import ImageRow from './hometech';
import callICon from '../../images/call.svg'
import Faqs from '../faq/faqs';
import { useNavigate } from 'react-router-dom';
import rightArrow from '../../images/arrowRight.svg';

const Home = () => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col overflow-x-hidden'>
      {/* Navbar at the top */}
      <HomeNavigation />

      {/* Content Sections */}
      <div className="pt-[85px] md:pt-[117px] lg:pt-[151px] md:pt-[142px] h-min-screen "> {/* Padding top to avoid overlap with fixed navbar */}
        {/* Section 1 */}
        <section className=" h-fit py-[23px] md:py-[51px] lg:py-[23px] xl:py-[56px] flex flex-col items-center justify-center space-y-[6px] ">
          <h1 className="px-[35px] md:px-[128px] text-center text-xxl md:text-huge lg:text-xb font-[900]">
            Innovate, Transform, Excel: <span className='text-modeepblue'>The Modenbo Way </span>
          </h1>
          <p className=" px-[54px] md:px-[128px] lg:px-[253px] xl:px-[461px] text-center text-sm font-normal">
            Empowering global businesses with data-driven software solutions tailored to local challenges and opportunities
          </p>
        </section>

        {/* Section 2 */}
        <section className="h-fit py-[52px] md:py-[33px] lg:py-[51px] flex  ">
          <Carousel />
        </section>

        {/* Section 3 */}
        <section className="h-fit py-[44px] lg:py-[53px] xl:py-[93px] flex items-center ">
          <AboutUs />
        </section>

        {/* Section 4 */}
        <section className="h-fit py-[41px] lg:py-[51px] xl:py-[36px] flex items-center justify-center">
          <Services />
        </section>

        {/* Section 5 */}
        <section className=" h-fit pt-[53px] pb-[83px] flex-col items-center justify-center ">
          <div className="flex flex-col items-center">
            <h2 className="text-sm h-[30px] px-[18px] mb-[16px] font-bold border-none rounded-[50px] bg-momediumblue flex items-center justify-center">
              Technologies
            </h2>
            <h3 className="text-xxl md:text-huge lg:text-xb font-[900] mb-[6px] ">What we use to build</h3>
            <p className="px-[29px] text-center text-sm font-normal mb-[29px] xl:px-[473px] xl:mb-[42px]">
              A collection of software and tools we use to build powerful products for you
            </p>
            {/* <div className='flex flex-row gap-x-[32.33px] px-[33px]'></div> */}
           
          </div>
          <ImageRow />
        </section>


        {/* Section 5 */}
        <section className=" h-fit px-[33px] md:px-[157px] lg:px-[67px]  xl:px-[102px] pt-[40px] pb-[73px]  md:pb-[87px] lg:pb-[135px]  xl:pb-[150px] flex items-center justify-center ">
          <div className="flex flex-col items-center">
            <h2 className="text-sm h-[30px] px-[18px] mb-[16px] font-bold border-none rounded-[50px] bg-momediumblue flex items-center justify-center">
              Frequently Asked Questions
            </h2>
            <h3 className="text-xxl md:text-huge lg:text-xb font-[900] mb-[6px] ">Question? Answers.</h3>
            <p className="px-[29px] text-center text-sm font-normal mb-[29px] xl:px-[473px] xl:mb-[42px]">
              Loved by businesses worldwide
            </p>
            {/* <div className='flex flex-row gap-x-[32.33px] px-[33px]'></div> */}
            <Faqs slice={6} />
            <button onClick={() => { navigate("/faq") }} className='mt-[24px] px-[15px] h-[30px] lg:h-[40px] flex text-white text-center items-center justify-center text-base font-bold lg:text-sm lg:text-base xl:text-lg bg-modeepblue rounded-[50px] flex-shrink-0   '>
              View All
              <img src={rightArrow} alt="Arrow Icon" className="pl-[3px]" />
            </button>


          </div>
        </section>

        {/* Section 6 */}
        {/* <section className="h-fit md:h-[602px] bg-modeepblue  py-[28px] flex items-center justify-center">
        <Pricing/>
        </section> */}

        <section className=" bg-modeepblue text-white py-[35px] lg:py-[53px] flex items-center justify-center">
          <div className="flex flex-col items-center">
            <h3 className="text-xl md:text-xxl lg:text-huge xl:text-xb font-black mb-[9px] md:mb-[2px] ">Ready to get started?</h3>
            <p className="px-[29px] text-center text-sm font-normal ">
              Reach out to us for inquiries, support, or
              feedback. We’d love to hear from you.
            </p>
            <span className='font-bold mb-[23px] lg:mb-[31px] xl:mb-[33px]'>contactus@modenbo.com
            </span>
            <p className="text-sm font-normal flex items-center gap-2">
              <img src={callICon} alt="call" className="" />
              Call us now:
              <span className="font-black">+233 533 373 736</span>
            </p>            {/* <div className='flex flex-row gap-x-[32.33px] px-[33px]'></div> */}


          </div>
        </section>


        {/* Section 7 */}
        <section className=" hidden h-screen  flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Section 7</h1>
        </section>

        {/* Section 8 */}
        <section className="hidden h-screen bg-gray-800 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Section 8</h1>
        </section>

        {/* Section 9 */}
        <section className="hidden h-screen bg-gray-900 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Section 9</h1>
        </section>

        {/* Section 10 */}
        <section className="hidden h-screen bg-black flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Section 10</h1>
        </section>
      </div>

      <Footer />

    </div>
  );
};

export default Home;
