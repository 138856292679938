// Portfolio code goes here
import React from 'react';
import HomeNavigation from '../navigation/homeNavigation';
import Footer from '../footer/Footer';
import SectionHeader from '../sectionheader'
import image1 from './assets/MOD1 PORTFOLIO PAGE.jpg';
import image2 from './assets/MOD2 PORTFOLIO PAGE.jpg';
import image3 from './assets/MOD3 PORTFOLIO PAGE.jpg';
import rightArrow from '../../images/arrowRightblue.svg';


const Portfolio = () => {
  const images = [
    {
      src: image1,
      title: "Goodifly",
      description: "Turn your personal travel into deliveries",
      link: "https://www.goodifly.com"

    },
    {
      src: image2,
      title: "Rate A Vendor",
      description: "Discover authentic reviews about your favorite vendors",
      link: "https://www.rateavendor.com"
    },
    {
      src: image3,
      title: "Peepowatch",
      description: "Crowdfunding with unmatched transparency"
    }


  ];
  return (



    <div className='flex flex-col overflow-x-hidden'>
      {/* Navbar at the top */}
      <HomeNavigation />

      {/* Content Sections */}
      <div className="pt-[85px] md:pt-[117px] lg:pt-[151px] md:pt-[142px] px-[33px]  md:px-[44px] lg:px-[67px] xl:px-[102px] h-min-screen "> {/* Padding top to avoid overlap with fixed navbar */}

        {/* Section 1 */}
        <section className=" h-fit py-[34px] md:py-[51px] lg:py-[27px] xl:py-[52px] flex flex-col items-center justify-center gap-[44px] md:gap-[47px] ">
          <SectionHeader title="Portfolio "
            subtitle="Our Work"
            description="Explore some of our latest app creations"
            descriptionClass="px-[12px]" />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[50px] md:gap-y-[46px] md:gap-x-[26px] lg:gap-y-[65px] lg:gap-x-[20px] xl:gap-y-[111px] xl:gap-x-[60px]">
            {images.map((image, index) => (
              <div key={index} className="flex flex-col gap-[5px] xl:gap-[8px]">
                {image.link ? (
                  <> <a href={image.link} target="_blank" rel="noreferrer">
                  <img src={image.src} alt={image.title} className=" h-[198px] w-full md:w-[315px] md:h-[223px]  lg:w-[435px] lg:h-[308px]  xl:w-[588px] xl:h-[418px] rounded-[10px]  mb-[4px]  xl:mb-[7px] cursor-pointer" />
                </a>
                  <h2 className="text-sm xl:text-lg font-normal">{image.title}</h2>
                    <p className="text-sm xl:text-lg font-[900] mb-[8px]">{image.description}</p>
                    <button onClick={() =>  window.open(image.link, '_blank')}  className=' w-fit px-[15px] h-[30px] lg:h-[40px] flex text-modeepblue text-center items-center justify-center text-base font-bold lg:text-sm lg:text-base xl:text-lg border border-modeepblue rounded-[50px] flex-shrink-0   '>
                      View project
                      <img src={rightArrow} alt="Arrow Icon" className="pl-[3px] fill-black" />
                    </button>
                   
                  </>

                ) : (
                  <div className="flex flex-col gap-[5px] xl:gap-[8px]" >
                    <img src={image.src} alt={image.title} className=" h-[198px] w-full md:w-[315px] md:h-[223px]  lg:w-[435px] lg:h-[308px]  xl:w-[588px] xl:h-[418px]  rounded-[10px]  mb-[4px] cursor-default" />
                    <h2 className="text-sm xl:text-lg font-normal">{image.title}</h2>
                    <p className="text-sm xl:text-lg font-[900] mb-[8px]">{image.description}</p>
                    <button  className=' w-fit px-[15px] h-[30px] lg:h-[40px] flex text-[#70707080] text-center items-center justify-center text-base font-black lg:text-sm lg:text-base xl:text-lg  bg-molightgray rounded-[50px] flex-shrink-0   '>
                      Upcoming
                      
                    </button>
                    {/* <p className="text-xs">Upcoming Project</p> */}
                  </div>
                )}

              </div>
            ))}
          </div>


        </section>





      </div>

      <Footer />

    </div>
    // <>
    //     <HomeNavigation />
    //     <img src={portLeft} alt="" className=" w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full z-[-1] mt-[70px] ml-[-20px] absolute" />
    //   <div className=" mb-[260px] w-full px-2 sm:px-[12] md:px-[22px] lg:px-[32px] xl:px-[96px] flex flex-col justify-center items-center">
    //     <div className=" mt-[55px] sm:mt-[65px] md:mt-[75px] lg:mt-[85px] xl:mt-[113px] text-center">
    //       <h1 className=" font-bold text-[30px] sm:text-[50px] leading-normal">
    //         Portfolio
    //       </h1>
    //       {/** Projects */}
    //       <div className=" w-full flex  flex-wrap gap-[16px] sm:gap-[26px] md:gap-[46px] lg:gap-[66px] xl:gap-[8.75rem] justify-center items-center">
    //         {/** Project */}
    //         <div className=" w-[240px] sm:w-[340px] md:w-[380px] lg:w-[400px] xl:w-[440px] mt-[32px] sm:mt-[40px] md:mt-[50px] lg:mt-[60px] xl:mt-[70px] flex flex-col text-left">
    //           <div className=" px-3 md:px-0 w-full h-[200px] sm:h-[300px] xl:h-[340px] rounded-[20px] bg-goodifly flex justify-center items-center bg-center bg-cover" />
    //           <h1 className=" mt-6 text-[20px] sm:text-[25px] leading-[30.33px] font-bold">Goodifly</h1>
    //           <h1 className=" text-[#707070] text-[16px] sm:text-[18px] leading-[21.83px] mt-2">
    //             Think of it as the &apos;Uber&apos; or &apos;Airbnb&apos; of flight travels.
    //             Goodifly redefines air freight, allowing seamless
    //             deliveries across borders by connecting users with
    //             travelers and leveraging shared flight space.
    //           </h1>
    //           <a href="https://www.goodifly.com" target="_blank" rel="noreferrer" className=" mt-7 cursor-pointer w-[180px] h-[60px] rounded-[50px] flex items-center justify-center text-center bg-[#4248E2]">
    //             <h1 className=" text-[#FFFFFF] text-[16px] lg:text-[18px] leading-[21.83px]">View project</h1>
    //           </a>
    //         </div>

    //         {/** Project */}
    //         <div className=" w-[240px] sm:w-[340px] md:w-[380px] lg:w-[400px] xl:w-[440px] mt-[32px] sm:mt-[40px] md:mt-[50px] lg:mt-[60px] xl:mt-[70px] flex flex-col text-left">
    //           <div className=" px-3 md:px-0 w-full h-[200px] sm:h-[300px] xl:h-[340px] rounded-[20px] bg-rateAvender flex justify-center items-center bg-center bg-cover" />
    //           <h1 className=" mt-6 text-[20px] sm:text-[25px] leading-[30.33px] font-bold">Rate A Vendor</h1>
    //           <h1 className=" text-[#707070] text-[16px] sm:text-[18px] leading-[21.83px] mt-2">
    //             Rate A Vendor empowers users to curate
    //             exceptional events through listing, reviewing, and
    //             rating vendors. We&apos;re enhancing event planning by
    //             offering a platform for vendor listing and reviews
    //           </h1>
    //           <a href="https://www.rateavendor.com" target="_blank" rel="noreferrer" className=" mt-7 cursor-pointer w-[180px] h-[60px] rounded-[50px] flex items-center justify-center text-center bg-[#4248E2]">
    //             <h1 className=" text-[#FFFFFF] text-[16px] lg:text-[18px] leading-[21.83px]">View project</h1>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="flex flex-row-reverse z-[-1] relative">
    //     <img src={portRight} alt="" className=" -mt-[18rem] -mr-[20px] w-[64px] h-[169px] lg:w-[164px] lg:h-[269px] max-w-full float-right z-[-1] absolute" />
    //   </div>
    //   <Footer />
    // </>


  );
}

export default Portfolio;
