import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer/Footer'
import HomeNavigation from '../navigation/homeNavigation'
import arrowIcon from '../../images/arrowRight.svg';
import Faqs from './faqs';





const Faq = () => {

    // Decode the data after retrieving it
    function decodeData(encodedData) {
        return atob(encodedData); // Base64 decoding
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Smooth scrolling animation
        });
    };
    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <div className=' h-screen items-center'>
            <HomeNavigation />
            {/* FAQ section */}

            <div className='flex flex-col items-center pt-[120px] px-[33px] md:pt-[164px] md:px-[157px] lg:pt-[172px] lg:px-[67px] xl:pt-[221px] lg:px-[102px]' >
                <p className=" flex text-sm h-[30px] px-[18px] mb-[16px] font-bold border-none rounded-[50px] bg-momediumblue items-center justify-center text-center " >
                    Frequently Asked Questions
                </p>
                <h3 className="text-xxl md:text-huge lg:text-xb   font-black mb-[6px]  ">Question? Answers</h3>
                <p className="px-[29px] md:px-[74px] lg:px-[129px] xl:px-[359px] text-center text-sm font-normal mb-[46px]">
                    Loved by businesses worldwide                </p>
                <Faqs/>
            </div>

            {/* FAQ Section End*/}

            <div className="flex flex-col w-full items-center justify-center text-center gap-[16px] mt-[41px]  md:mt-[57px] lg:mt-[53px] xl:mt-[83px] ">
                <h1 className="  text-lg  lg:text-xl xl:text-xxl font-black">
                    Still have a question?
                </h1>
                <h1 className=" lg:mx-[217px] xl:mx-[425px] text-sm font-normal mx-[42px] md:mx-[141px]">
                If you cannot find answers to your questions in our FAQs, you can always contact us. We will answer to you shortly
                </h1>
               
                <button onClick={() => { window.location.href = "mailto:contactus@modenbo.com";  }} className=' flex text-white  px-[18px] lg:px-[15px] items-center justify-center text-base lg:text-sm bg-modeepblue h-[30px] lg:h-[40px] rounded-[50px] flex-shrink-0 leading-[18.9px]  font-[900]'>contactus@modenbo.com
                    <img src={arrowIcon} alt="Arrow Icon" className="ml-[3px]" />
                </button>
            </div>
            <Footer />
        </div>
    );
}

export default Faq

