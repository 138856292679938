import React, { useState } from 'react';
import image1 from './assets/React.png';
import image2 from './assets/Google cloud.png';
import image3 from './assets/figma.png';
import image4 from './assets/django.png';
import image5 from './assets/python.png';
import image6 from './assets/postgres.png';
import image7 from './assets/TABLEAU.png';
import image8 from './assets/Github.png';
import image9 from './assets/Swift.png';
import image10 from './assets/Node JS.png';
import image11 from './assets/MongoDB.png';
import image12 from './assets/Angular.png';
import image13 from './assets/flutter.png';




function ImageRow() {
    const images = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
        image13,
    ];

    return (
        <div className="relative w-full overflow-hidden">
            <div className="flex space-x-[35px] md:space-x-[40.5px] lg:space-x-[75px] xl:space-x-[59px] animate-scroll">
                {/* Duplicate images for seamless scrolling */}
                {[...images, ...images, ...images].map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Image ${index + 1}`}
                        className="h-[40px] md:h-[52px] lg:h-[83px] w-auto"
                    />
                ))}
            </div>

            <style jsx>{`
                :root {
                    --image-width: 176px; /* Adjust based on your image + spacing */
                    --animation-speed: ${images.length * 2}s;
                }

                @keyframes scroll {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(calc(-1 * var(--image-width) * ${images.length}));
                    }
                }

                .animate-scroll {
                    white-space: nowrap; /* Prevent images from wrapping */
                    animation: scroll var(--animation-speed) linear infinite;
                }
            `}</style>
        </div>
    );
}

export default ImageRow;
