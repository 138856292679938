import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import homelogo from '../../images/home_logo.png';
import menuOpen from '../../images/menu_open.svg';
import menuClose from '../../images/menu_close.svg';
import arrowIcon from '../../images/arrowRight.svg';
import DropdownMenu from '../dropdownmenu';


const HomeNavigation = () => {

    const navigate = useNavigate()

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [click, setClick] = useState(false);


    const closeMobileMenu = () => setClick(false);

    const menuItems = [
       
        { label: 'Career', onClick: () => window.location.href = '#career' },
        { label: 'Foundation', onClick: () => window.location.href = '#foundation' },
    ];







    return (
        <nav className=" fixed top-0 left-0 right-0 h-[85px] md:h-[117px] lg:h-[151px] xl:h-[142px] bg-white flex items-center justify-between px-[33px] md:px-[44px] lg:px-[67px]  xl:px-[102px] z-50">
            {/* Logo in Navbar */}
            <div className="flex items-center space-x-4">
                <NavLink to="/" className="" onClick={closeMobileMenu}>
                    <img src={homelogo} alt="Home Logo" className="h-[28.61px] w-[146px] lg:h-[32.33px] lg:w-[165px] xl:h-[42.67px] xl:w-[220px] " />
                </NavLink>
            </div>

            {/* Always Show Menu Open Icon in Navbar */}
            <button
                onClick={toggleMenu}
                className=" focus:outline-none md:hidden z-50"
            >
                <img src={menuOpen} alt="Menu Icon" />
            </button>

            {/* Navbar Links (hidden on mobile) */}
            <div className="hidden md:flex md:space-x-[22px] lg:space-x-[30px] md:text-base lg:text-sm items-center font-[900] ">
                <NavLink to="/portfolio">
                    Portfolio
                </NavLink>
                <NavLink to="/about">
                    About us
                </NavLink>
                {/* <NavLink to="/portfolio">
                    Blog
                </NavLink> */}
                <NavLink to="/faq">
                    FAQs
                </NavLink>
                {/* <NavLink to="/careers">
                                   Careers
                                </NavLink> */}
                {/* More Dropdown large screens */}
                {/* <DropdownMenu buttonLabel="More" menuItems={menuItems} /> */}

                <button onClick={() => { window.location.href = "mailto:contactus@modenbo.com"; closeMobileMenu() }} className=' flex text-white  px-[18px] lg:px-[15px] items-center justify-center text-base lg:text-sm bg-modeepblue h-[30px] lg:h-[40px] rounded-[50px] flex-shrink-0 leading-[18.9px]  font-[900]'>Let's talk
                    <img src={arrowIcon} alt="Arrow Icon" className="ml-[3px]" />
                </button>


            </div>

            {/* Sidebar Menu */}
            {isMenuOpen && (
                <>
                    <div className="transition-transform duration-300 ease-in fixed top-0 left-0 w-[80%] h-full bg-white  z-40 px-[29px] flex flex-col space-y-6">
                        {/* Sidebar Header with Logo and Close Icon */}
                        <div className=" h-[85px] flex items-center justify-between">
                            {/* Logo inside the sidebar */}
                            <NavLink to="/" className="" onClick={toggleMenu}>
                                <img src={homelogo} alt="Home Logo" className="h-[28.61px] w-[146px]" />
                            </NavLink>


                            {/* Close Icon Inside Sidebar */}
                            <button onClick={toggleMenu} className=" focus:outline-none">
                                <img src={menuClose} alt="Close Menu" />
                            </button>
                        </div>
                        {/* Sidebar Links */}
                        <div className="flex flex-col mt-[27px]">
                            <div className=' flex flex-col space-y-[15px] text-lg font-bold '>
                                <NavLink to="/portfolio">
                                    Portfolio
                                </NavLink>
                                <NavLink to="/about">
                                    About us
                                </NavLink>
                                {/* <NavLink to="/portfolio">
                                    Blog
                                </NavLink> */}
                                <NavLink to="/faq">
                                    FAQs
                                </NavLink>
                                {/* <NavLink to="/careers">
                                   Careers
                                </NavLink> */}
                                {/* More Dropdown in Sidebar */}
                                {/* <DropdownMenu buttonLabel="More" menuItems={menuItems} /> */}

                            </div>
                            <button onClick={() => { window.location.href = "mailto:contactus@modenbo.com"; closeMobileMenu() }} className=' flex mt-[35px] text-[#ffffff] text-center items-center justify-center text-base bg-modeepblue w-full h-[30px] rounded-[50px] flex-shrink-0 font-bold'>Let&apos;s talk   <img src={arrowIcon} alt="Arrow Icon" className="ml-[3px]" /> </button>
                          
                        </div>


                    </div>

                    {/* Overlay covering remaining 20% */}
                    <div className="fixed top-0 right-0 w-[20%] h-full bg-black bg-opacity-30 z-30" onClick={toggleMenu} />
                </>
            )}
        </nav>
    );
};

export default HomeNavigation;
