import React from 'react';

const TeamMember = ({ image, name, position,title }) => {
    return (
        // <div className="flex flex-col   border rounded-[15px]  ">
        //     {/* Image */}
        //     <img src={image} alt={name} className="w-fit h-[151px] object-cover " />
           
        //     {/* Name */}
        //     <div className='h-[55px] bg-modeepblue rounded-b-[15px] text-white'>
        //         <h3 className="text-lg font-bold">{name}</h3>

        //         {/* Position */}
        //         <p className="text-sm text-gray-500">{position}</p>
        //     </div>
        // </div>

         <div className=" w-[145px] md:w-[210px] lg:w-[280px] flex flex-col  border-none  flex-grow ">
            {/* Image */}
            <img src={image} alt={name} className=" h-[151px] md:h-[195px] lg:h-[245px] object-cover rounded-t-[15px] " />
           
            {/* Name */}
            <div className='flex flex-col text-center h-[65px] md:h-[70px] lg:h-[85px] items-center justify-center bg-modeepblue bg-modeepblue rounded-b-[15px] text-white gap-[1px] px-2 py-2 md:p-0'>
                <h3 className="text-[14px] leading-[16.98px] font-bold">{name}</h3>

                {/* Position */}
                <p className="text-sm ">{position}</p>
                <p className="text-sm ">{title}</p>
            </div>
        </div>
        
    );
};

export default TeamMember;
