import React from 'react';

const SectionHeader = ({
  title = "",
  subtitle = "",
  subtitleSmall="",
  description = "",
  titleClass = "",
  subtitleClass = "",
  subtitleSmallClass="",
  descriptionClass = "",
  SectionClass=""
}) => {
  return (
    <div className={`flex flex-col text-center items-center justify-center ${SectionClass}`}>
      <h2 className={`h-[30px] px-[18px]  mb-[16px] text-sm font-bold rounded-[50px] bg-momediumblue flex justify-center items-center ${titleClass}`}>
        {title}
      </h2>
      
      <h3 className={`text-xxl md:text-huge lg:text-xb font-[900] mb-[6px]  ${subtitleClass}`}>
        {subtitle}
      </h3>

      <h3 className={`text-[18px] leading-[24.3px] lg:text-[25px] lg:leading-[33.75px] font-[900] mb-[6px] lg:mb-[20px] ${subtitleSmallClass}`}>
        {subtitleSmall}
      </h3>
      <p className={` text-sm font-normal  ${descriptionClass}`}>
        {description}
      </p>
    </div>
  );
};

export default SectionHeader;
