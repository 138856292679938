import React from 'react';
import logo from '../../images/modenbo_logo.png';
import linkedin from '../../images/linkedin_icon.png';
import instagram from '../../images/instagram_icon.png';
import homelogo from '../../images/home_logo.png';
import mail from '../../images/mail_icon.svg';
import call from '../../images/call_icon.svg';
import handleSendEmail from '../email/SendEmail';

// const Footer = () => (
//   <footer className="footer pt-5">
//     <div className="footer-content">
//       <div className="footer-logo">
//         <a href="/">
//           <img src={logo} alt="Modenbo Technologies Logo" />
//         </a>
//       </div>
//       <div className="footer-links">
//         <a href="/privacy">Privacy Policy</a>
//         <a href="/terms">Terms of Use</a>
//       </div>
//       <div className="footer-icons">
//         <a
//           href="https://www.linkedin.com/company/modenbo-technologies"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <img src={linkedin} alt="Modenbo Technologies Linkedin" />
//         </a>
//         <a
//           href="https://www.instagram.com/modenbotech"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <img src={instagram} alt="Modenbo Technologies Instagram" />
//         </a>
//       </div>
//     </div>
//     <div className="footer-contact">
//       <button
//         type="button"
//         aria-label="Send email to modenbo"
//         onClick={handleSendEmail}
//         className="footer-mail"
//       >
//         <img src={mail} alt="Modenbo Technologies Email" />
//         <p>contactus@modenbo.com</p>
//       </button>
//       <a href="/" className="footer-mail">
//         <img src={call} alt="Modenbo Technologies Phone" />
//         <p>+233 53 337 3736</p>
//       </a>
//     </div>
//     <p className="footer-copyright">2023 © Copyright. Modenbo Technologies</p>
//   </footer>
// );

// export default Footer;



import { useNavigate } from 'react-router-dom'
const Footer = () => {
  const navigate = useNavigate()
  return (

    <div className="pt-[54px] px-[33px] md:px-[44px] lg:px-[67px] xl:px-[102px] pb-[29px]">
      <a href="/">
         <img src={homelogo} alt="Home Logo" className="h-[21px] w-[108px] md:h-[29.37px] md:w-[150px]  mb-[30px]" />
      </a>
      <div className='  lg:whitespace-nowrap flex flex-col-reverse  lg:ml-0 md:ml-0 md:pb-[63px]  lg:flex-row  md:flex-row lg:px-[0]  lg:text-center font-normal text-xs md:text-base md:items-center md:justify-center  lg:items-center md:justify-between '>
        <div>
          <h1 className=' hidden md:text-base  md:flex font-normal items-center'> <span className=''>©</span>Modenbo Technologies 2023. All rights reserved</h1>
        </div>


        <div className='flex flex-col gap-[3px] md:gap-[20px] text-base lg:text-[14px] lg:leading-[16.98px] md:text-[14px] md:leading-[18.9px] font-normal lg:gap-[30px] lg:mb-0 lg:flex-row md:flex-row'>
          <h1 onClick={() => navigate("/privacy")} className=' cursor-pointer'>Privacy Policy</h1>
          <h1 onClick={() => navigate("/terms")} className=' cursor-pointer'>Terms of Use</h1>
        </div>



        <div className='flex gap-[10px] mb-[21px] md:mb-0 '>
          <a href="https://www.linkedin.com/company/modenbo-technologies" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" className='  w-[30px]  h-[30px] md:w-[40px]  md:h-[40px]   ' /></a>
          <a href="https://www.instagram.com/modenbotech" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" className=' w-[30px]  h-[30px] md:w-[40px]  md:h-[40px]   ' /></a>
        </div>

      </div>
      <div >
        <h1 className=' pt-[30px] pb-[8px] whitespace-nowrap flex justify-center items-center gap-[1px] text-[12px] leading-[16.2px] font-normal lg:hidden md:hidden '> <span className=''>©</span>Modenbo Technologies 2023. All rights reserved</h1>
      </div>
    </div>

  )
}

export default Footer

