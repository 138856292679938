import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import About from './components/about/About';
import Portfolio from './components/portfolio/Portfolio';
// import Contact from './components/contactus/Contact';
import Privacy from './components/privacy/Privacy';
import Terms from './components/terms/Terms';
import Faq from './components/faq/faq';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        {/* <Route exact path="/contact" element={<Contact />} /> */}
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/faq" element={<Faq/>} />
      </Routes>
    </Router>
  );
}

export default App;
