import React from 'react';

const Services = () => {
  const servicesList = [
    "Web Development",
    "Cloud Solutions",
    "Landing Pages",
    "IT Consulting",
    "Data Analytics",
    "Mobile Apps",
    "Web Apps",
    "Automation"
  ];

  return (
    <div className="flex flex-col items-center">
      <h2 className="h-[30px]  px-[18px] mb-[16px] text-sm font-bold border-none rounded-[50px] bg-momediumblue flex justify-center items-center">
        Services
      </h2>
      <h3 className="text-xxl  md:text-huge lg:text-xb font-[900] mb-[6px] px-[67px] md:px-[146px] text-center">You name it, We deliver</h3>
      <p className="px-[40px] md:px-[99px] text-center text-sm font-normal mb-[30px] md:mb-[16px] xl:mb-[40px] xl:px-[448px]">
        If you are a business or an individual, and any of this sounds familiar, we are a great team to work with
      </p>

      {/* Services Grid */}
      {/* <div className="md:hidden grid grid-cols-2 md:grid-cols-3 gap-[6px]  justify-center ">
        {servicesList.map((service, index) => (
          <div
            key={index}
            className={`flex border whitespace-nowrap border-modeepblue text-modeepblue rounded-[50px] px-[18px] h-[30px] w-fit md:h-[40px] text-center items-center justify-center font-bold text-sm 
        ${index % 2 === 0 ? 'justify-self-end self-end ' : (index % 3 === 0) ? 'md:justify-self-start md:self-start ' : ''}`}
          >
            {service}
          </div>
        ))}
      </div> */}

      <div className=' hidden md:flex flex-col gap-y-[18px] px-[33px] items-center'>
        <div className="flex flex-row  gap-x-[18px]  items-end">
          {servicesList.slice(0, 3).map((service, index) => (
            <div
              key={index}
              className="flex border border-modeepblue text-modeepblue rounded-[50px]  px-[18px] w-max h-[30px] md:h-[40px] text-center items-center justify-center font-bold text-sm "
            >
              {service}
            </div>
          ))}
        </div>
        <div className="flex flex-row gap-x-[18px] justify-center">
          {servicesList.slice(3, 6).map((service, index) => (
            <div
              key={index}
              className="flex border border-modeepblue text-modeepblue rounded-[50px] px-[18px] w-max h-[30px] md:h-[40px] text-center items-center justify-center font-bold text-sm "
            >
              {service}
            </div>
          ))}
        </div>
        <div className="flex flex-row gap-x-[18px] justify-center">
          {servicesList.slice(6).map((service, index) => (
            <div
              key={index}
              className="flex border border-modeepblue text-modeepblue rounded-[50px] px-[18px] w-max h-[30px] md:h-[40px] text-center items-center justify-center font-bold text-sm "
            >
              {service}
            </div>
          ))}
        </div>
      </div>




      <div className=' md:hidden flex flex-row gap-x-[6px] px-[33px]'>
      <div className="flex flex-col  gap-y-[16px]  items-end">
        {servicesList.slice(0, 4).map((service, index) => (
          <div
            key={index}
            className="flex border border-modeepblue text-modeepblue rounded-[50px]  px-[18px] w-max h-[30px] md:h-[40px] text-center items-center justify-center font-bold text-sm "
          >
            {service}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-y-[16px] justify-center">
        {servicesList.slice(4).map((service, index) => (
          <div
            key={index}
            className="flex border border-modeepblue text-modeepblue rounded-[50px] px-[18px] w-max h-[30px] md:h-[40px] text-center items-center justify-center font-bold text-sm "
          >
            {service}
          </div>
        ))}
      </div>
      </div>

     
      {/* <div className="hidden  md:grid grid-cols-2 md:grid-cols-3 gap-[18px] gap-y-[24px] justify-center ">
        {servicesList.map((service, index) => (
          <div
            key={index}
            className="flex border border-modeepblue text-modeepblue w-max rounded-[50px] px-[18px] h-[40px] text-center items-center items-end font-bold text-sm"
          >
            {service}
          </div>
        ))}
      </div> */}


      {/* <div className="grid grid-cols-2 md:grid-cols-3 gap-[6px] justify-center">
  {servicesList.map((service, index) => (
    <div
      key={index}
      className={`flex border whitespace-nowrap border-modeepblue text-modeepblue rounded-[50px] px-[18px] h-[30px] md:h-[40px] w-auto text-center items-center justify-center font-bold text-sm 
        ${index % 2 === 0 ? 'justify-self-end md:justify-self-auto self-end' : ''}`}
    >
      {service}
    </div>
  ))}
</div> */}



    </div>
  );
};

export default Services;
