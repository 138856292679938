import React from 'react';
import PricingCard from '../pricing/pricingcard';


const Pricing = () => {
    return (
        <div className="flex flex-col items-center ">
            {/* <h2 className="h-[30px] px-[18px] mb-[16px] font-bold border-none rounded-[50px] bg-momediumblue flex justify-center items-center ">Pricing</h2>
            <h3 className="text-xxl font-[900] mb-[6px] text-white ">Part of your team?</h3>
            <p className="px-[29px] text-center text-sm font-normal text-white mb-[16px]">
                No hidden contract surprises. Same price
                and value every month, with the freedom
                to cancel anytime
            </p> */}
            <h2 className=" text-sm h-[30px] px-[18px] mb-[16px] font-bold border-none rounded-[50px] bg-momediumblue flex items-center justify-center">
            Pricing
            </h2>
            <h3 className="text-white text-xxl md:text-huge lg:text-xb font-[900] mb-[6px] ">Part of your team?</h3>
            <p className="text-white px-[29px] text-center text-sm font-normal mb-[16px] md:mb-[41px] lg:mb-[44px]">
            No hidden contract surprises. Same price
                and value every month, with the freedom
                to cancel anytime
            </p>
            <div className='flex flex-col gap-[20px] md:flex-row md:gap-[17px] lg:gap-[20px] xl:gap-[30px]'>
            <PricingCard/>
            <PricingCard/>
            <PricingCard/>
            </div>

        </div>
        
    );
};

export default Pricing;