import React, { useEffect, useState } from 'react'
import faqview from './assets/faq_view.svg'
import faqclose from './assets/faq_close.svg'

const formatAnswer = (answer) => {
    if (typeof answer === 'string') {
        return <p>{answer}</p>;
    }

    if (Array.isArray(answer)) {
        return (
            <>
                {answer.map((part, index) => {
                    switch (part.type) {
                        case 'bold':
                            return <span key={index} className="font-bold">{part.content}</span>;
                        case 'heading':
                            return <span key={index} className="font-bold ">{part.content}</span>;
                        case 'list':
                            return (
                                <ul key={index} className="ml-10 list-disc ">
                                    {part.content.map((item, i) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                </ul>
                            );
                        case 'innerlist':
                            return (
                                <ul key={index} className="ml-20 list-disc ">
                                    {part.content.map((item, i) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                </ul>
                            );
                        case 'newline':
                            return <p key={index} className="my-4"></p>;

                        case 'link':
                            return <a href="mailto:contactus@goodifly.com" className=" underline text-[#EB0728] lg:w-[779px] text-[16px] sm:text-[18px] font-normal lg:leading-[21.83px] leading-[19.41px]">
                                <span key={index} >{part.content}</span>
                            </a>;

                        case 'indent':
                            return <p key={index} className="ml-5"></p>;
                        case 'text':
                        default:
                            return <span key={index}> {part.content}</span>;
                    }
                })}
            </>
        );
    }

    return null;
};


// Individual FAQ Item component
const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false); // State to track if answer is visible

    const toggleAnswer = () => {
        setIsOpen(!isOpen); // Toggle the state when button is clicked
    };

    return (

        <div
            onClick={toggleAnswer}
            className={`border place-self-start flex flex-col items-start justify-center py-[12px] md:py-[15px] lg:py-[13.5px] rounded-[15px]  pl-[19px] cursor-pointer overflow-hidden transition-all duration-200 ease-out  ${isOpen ? '' : 'h-auto'
                } `}
            style={{ transitionProperty: 'border-color, border-width' }} >
            {/* Question and icon */}
            <div className="flex  justify-between items-center gap-[7px] w-full">
                <h1 className="text-base font-black md:text-sm "> {question} </h1>
                <div className="flex items-center justify-center w-[20px] h-[20px] md:w-[25px] md:h-[25px] bg-momediumblue rounded-full shrink-0 mr-[19px]">
                    <img src={isOpen ? faqclose : faqview} alt={isOpen ? 'open' : 'close'} className="  " />
                </div>

            </div>


            {/* Conditionally render the answer */}
            <div
                className={`flex justify-between items-center ${isOpen ? 'h-fit opacity-100 ' : 'max-h-0 opacity-0'
                    }`}>
                <span className="lg:mt-[34px] mt-[20px] text-base  md:text-sm font-normal  flex-grow lg:mr-[60px]  mr-[30px]  "> {formatAnswer(answer)}</span>
            </div>
        </div>

    );
};


const Faqs = ({ slice = 21 }) => {


    const client = [

        { question: ' Is Modenbo an online-only company, or do you have an in-house office??', answer: 'Modenbo operates primarily as an online company, which allows us to serve clients across Ghana, the U.S., and beyond efficiently.' },
        {
            question: 'What kind of companies do you work with?',
            answer: 'We work primarily with startups, small to medium-sized enterprises (SMEs), and growing businesses in fintech, e-commerce, and education technology (EdTech). Our clients are usually based in Ghana or the U.S., but we’re expanding to other markets.'

        },
        {
            question: 'How can Modenbo help my business stand out in a competitive market?',
            answer: 'Modenbo combines local insights with global standards, helping businesses differentiate themselves by leveraging custom technology that addresses unique market challenges. We position ourselves as strategic partners, meaning we work with you to understand your business deeply, then design technology solutions that directly support your growth and competitive advantage.'

        },
        { question: ' What specific services do you offer?', answer: 'We specialize in software development, digital transformation consulting, and tailored technology solutions. Our expertise includes fintech applications (like mobile banking and payment systems), e-commerce platforms, and educational technology such as virtual classrooms and online learning tools.' },

        { question: '  Do you work with clients outside of Ghana?', answer: 'Yes, we serve both local and international clients. We focus on building long-term relationships with businesses in Ghana and U.S., and we’re open to expanding our reach into other regions as well.' },
        { question: ' What makes Modenbo different from other tech companies in Ghana', answer: 'Modenbo stands out due to our mix of local expertise and international standards. We don’t just build software; we provide strategic guidance and are dedicated to helping our clients achieve long-term digital transformation. Our deep technical expertise and agile approach also mean we can adopt the latest technologies quickly.' },
        {
            question: 'How much do your services cost? ',
            answer: 'Our pricing is value-driven, meaning we tailor our services to the specific needs and goals of each client rather than offering a one-size-fits-all approach. We’ll discuss your project requirements and budget in an initial consultation to ensure we create a solution that delivers the best ROI(Return on Investment) for your business.'
        },
        {
            question: 'Can you share examples of past projects or client success stories?',
            answer: 'Absolutely! We have a range of client case studies and testimonials that demonstrate our impact, especially in fintech, e-commerce, and EdTech. Kindly click on this link to view'
        },
        { question: 'How does Modenbo ensure project security and compliance?', answer: 'Security is a top priority for us, especially in sectors like fintech where data protection is critical. We follow global best practices for cybersecurity, including secure-by-design development, compliance with data protection regulations, and regular audits to keep your project safe.' },

        {
            question: 'What kind of support can I expect after my project is launched?', answer: 'We offer post-launch support, including system maintenance, updates, and performance tracking to ensure your technology continues to meet your needs. Our customer success team is dedicated to keeping clients informed and supported long-term, so you’ll always have assistance when needed. (Terms and Condition applied)'
        },
        { question: 'How long does it take to complete a project?', answer: ' Project timelines vary depending on the scope and complexity of the solution. After discussing your specific needs, we’ll provide a project timeline during our initial proposal stage. Generally, projects can range from a few weeks for smaller solutions to several months for more complex systems. ' },
        { question: 'Can Modenbo help us improve our current software or website?', answer: 'Yes, we offer consulting and development services to improve existing systems. Whether you need to optimize performance, enhance user experience, or add new features, we can work with your existing setup to upgrade it to your requirements.' },
        { question: ' How does Modenbo keep up with industry trends and emerging technologies?', answer: 'Our team stays current through continuous training, industry certifications, and active participation in global tech communities. We regularly evaluate new tools, methodologies, and technologies to ensure we’re offering our clients the latest and most effective solutions.' },
        { question: 'How do you measure the success of your projects?', answer: 'We set clear goals and KPIs with clients at the start of every project, then track these metrics to measure success. This can include performance indicators like increased website traffic, conversion rates, or specific operational improvements, depending on your goals. (Terms and conditions applied)' },
        { question: 'Do you offer consulting services, or is it all development-focused?', answer: 'Yes, we offer digital transformation consulting as well as development. This means we help businesses refine their technology strategy, optimize processes, and prepare for future growth with a clear, customized tech roadmap.' },
        { question: 'How do I get started working with Modenbo?', answer: 'Simply reach out to us through our website , LinkedIn or phone lines to schedule an initial consultation. We’ll discuss your needs, goals, and ideas in detail, then outline a tailored solution that meets your business objectives.' },
        { question: 'How do you handle client communications during projects?', answer: 'Communication is key for us. We use a project management system where clients can track progress, provide feedback, and see updates in real time. Our team holds regular check-ins to ensure transparency, and a dedicated account manager is available to answer questions and address concerns throughout the project.' },
        { question: 'What if my business needs change after the project begins?', answer: 'We understand that business needs can evolve, so we approach projects with flexibility in mind. If your needs change, we can discuss adjustments to the project scope and timeline to accommodate these changes while keeping your end goals on track.(Terms and conditions applied)' },
        { question: 'How do you ensure a good user experience for your clients’ customers?', answer: 'We prioritize user experience (UX) and usability in our development process. From user testing to intuitive design elements, we ensure that the end product is easy to navigate, functional, and meets the needs of your customers, enhancing satisfaction and engagement. Additionally, we have a dedicated expert in UX design on our team, ensuring that our solutions are crafted with top-tier design insights and best practices.' },
        { question: 'Do you provide training on using the software once it’s built?', answer: 'es, we provide full training sessions for your team on using and managing the software. This can include user manuals, step-by-step guides, or live training sessions, depending on what’s most effective for your business.' },
        { question: 'How do you approach projects with limited budgets?', answer: 'We understand that budget constraints are common, especially for startups and SMEs. We work closely with clients to prioritize features and phases of development to deliver the most value within your budget. We’ll advise on cost-effective solutions and scalable options that align with your financial and operational needs.' },


    ];

    return (


        <div className=" flex flex-col lg:grid lg:grid-cols-2 lg:items-start gap-y-[25px] lg:gap-x-[30px] xl:lg:gap-x-[76px]">
            {client.slice(0, slice).map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
        </div>

    );
}

export default Faqs