import React, { useEffect, useState } from 'react';
import image1 from '../../images/mobilePeepowatch.jpg';
import image2 from '../../images/mobileGoodifly.jpg';
import image3 from '../../images/mobileRateavendor.jpg';
import image4 from '../../images/tablet-desktopPeepowatch.jpg';
import image5 from '../../images/tablet-desktopRateavendor.jpg';
import image6 from '../../images/tabletdesktopGoodifly.jpg';
import rightArrow from '../../images/arrowRight.svg';
import { useNavigate } from 'react-router-dom';

const Carousel = () => {
  const [images, setImages] = useState([image1, image2, image3]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is 768px or more
      if (window.matchMedia('(min-width: 744px)').matches) {
        setImages([image4, image5, image6]); // Use desktop images
      } else {
        setImages([image1, image2, image3]); // Use mobile images
      }
    };

    // Initial check
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="relative w-full max-w-[100vw] mx-auto overflow-hidden">
      {/* Carousel container with animation */}
      <div className="flex animate-scroll">
        {/* Clone images for seamless looping */}
        {[...images, ...images, ...images, ...images].map((image, index) => (
          <div key={index} className="flex-shrink-0 w-[228px] h-[198px] md:w-[344px] md:h-[275px] md:w-[385px] md:h-[308px] mx-[5px] md:mx-[14px]">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-full rounded-[10px] object-cover"
            />
          </div>
        ))}
      </div>

      <div className='flex flex-row items-center gap-[130px] mt-[31px] md:mt-[28px] xl:mt-[30px] justify-center'>
        <button onClick={() => { navigate("/portfolio") }} className='px-[15px] md:px-[18px] h-[30px] lg:h-[40px] flex text-white text-center items-center justify-center text-base lg:text-sm  bg-[#050C9C] rounded-[50px] flex-shrink-0 font-bold'>
          Portfolio
          <img src={rightArrow} alt="Arrow Icon" className="" />
        </button>
      </div>

      {/* Add keyframes for smooth scrolling animation */}
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-${images.length * 238}px); /* Adjust based on image width + margin */
          }
        }
        
        .animate-scroll {
          animation: scroll ${images.length * 3}s linear infinite; /* Adjust speed based on number of images */
        }
      `}</style>
    </div>
  );
};

export default Carousel;
